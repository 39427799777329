import React from "react";

const experienceContent = [
  {
    year: "2022.7 - Present",
    position: "Blockchain developer",
    compnayName: "Blockways.Ltc",
    details: `I'm constantly learning and trying out new technologies and solutions now and working with smart contracts and dApps`,
  },
  {
    year: "2021.11 - 2022.6",
    position: "Web3 Engineer",
    compnayName: "Nifty Gateway",
    details: `Designed and developed web3 frontend of NFT marketplace`,
  },
  {
    year: "2020.6 -2021.9",
    position: "Frontend Engineer",
    compnayName: "Accelerlist",
    details: `I had handle the management for company content management system. Hygraph was one of the best choices for a "React CMS" because it based 100% on GraphQL and, as such, helps makes working with our structured content as simple Is possible.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
